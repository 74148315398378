<template>
  <div>
    <loader v-if="isLoading" message="Getting Apps ...." />
    <div v-else>
      <v-row v-if="emptyAppsList" class="d-flex pt-12 flex-column">
        <v-col cols="6" class="text-center align-self-center">
          <img :src="require(`@/assets/svgs/platonic-image.svg`)" />
          <div class="empty-text">
            <s-text color="black" weight="normal" size="md">
              Enjoy the benefits of going data free
            </s-text>
            <s-text color="black" weight="medium" size="lg">
              Start Listing your apps now
            </s-text>
            <s-btn
              type="button"
              color="primary"
              class="mt-4"
              elevation="0"
              small
              :to="{ name: 'createApp' }"
            >
              <v-icon class="mr-1">mdi-plus</v-icon> Create apps
            </s-btn>
          </div>
        </v-col>
      </v-row>
      <v-container v-else>
        <div class="flex-column align-center justify-center scroll-y">
          <v-row
            class="pa-1 ma-0 my-3"
            style="background: #e9e9e9; border-radius: 8px"
          >
            <v-col class="d-flex flex-column py-0" cols="5">
              <s-text weight="medium" color="gray" size="sm"> App name </s-text>
            </v-col>
            <v-col class="d-flex flex-column py-0" cols="3">
              <s-text weight="medium" color="gray" size="sm"> Status </s-text>
            </v-col>
            <v-col class="d-flex flex-column py-0" cols="3">
              <s-text weight="medium" color="gray" size="sm"> Created </s-text>
            </v-col>
          </v-row>
          <v-row
            v-for="app in apps"
            :key="app.slug"
            class="pa-0 ma-0 mt-3 app-card"
            @click="viewApp(app.slug)"
          >
            <v-col class="d-flex align-center" cols="5">
              <img
                class="mr-4"
                style="width: 24px"
                :src="require(`@/assets/svgs/network-ball.svg`)"
              />
              <!--<div class="d-flex align-center">
                <s-text color="black" weight="normal" size="md">
                  {{ app.endpoints[0].origin.fqdn }}
                </s-text>
                <span
                  v-if="app.endpoints.length > 1"
                  class="count-box bg-white px-1 ml-2"
                >
                  +{{ app.endpoints.length - 1 }}
                </span>
              </div>-->
              <s-text color="black" weight="medium">
                {{ app.name }}
              </s-text>
            </v-col>
            <v-col cols="3">
              <div v-if="app.deployed" class="d-flex">
                <s-icon
                  name="double-check"
                  class="d-block list_item__icon mr-2"
                />
                <s-text color="success"> Deployed </s-text>
              </div>
            </v-col>
            <v-col cols="3" class="d-flex align-center">
              <s-text class="text-truncate">
                {{ getHumanReadableDate(app.created_at) }}
              </s-text>
            </v-col>
            <v-col class="action_group d-flex justify-end">
              <s-icon name="arrow-circle-right" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDate } from '@/utils/date'

export default {
  name: 'Apps',
  data() {
    return {
      isLoading: false,
    }
  },
  components: {
    loader: Loader,
  },
  computed: {
    ...mapGetters({
      apps: 'app/apps',
    }),
    emptyAppsList() {
      return this.apps.length < 1
    },
  },
  methods: {
    getHumanReadableDate,
    async getApps() {
      this.isLoading = true
      await this.$store.dispatch('app/getApps')
      this.isLoading = false
    },
    async viewApp(id) {
      await this.$router.push({ name: 'appAnalytics', params: { id: id } })
    },
  },
  mounted() {
    this.getApps()
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 26px;
  height: 23px;
  color: var(--v-gray-base);
  font-size: 14px;
  font-weight: 600;
}
.bg-white {
  background: white;
}
</style>
